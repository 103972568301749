<template>
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar-menu></sidebar-menu>
      <div class="page-wrapper">
        <div class="content">
            <div class="d-flex align-items-center justify-content-between">

                <div>
                    <pageaddheader :title="title" :title1="title1" />
                </div>
                <div>
                    <button @click="importExcel" class="btn btn-primary me-2"><i class='bx bx-arrow-to-bottom me-2'></i>Import</button>
                    <button @click="exportExcel" class="btn btn-primary"><i class='bx bx-arrow-to-top me-2' ></i>Export</button>
                </div>
            </div>
          <div class="card">
            <div class="card-body">
                <div class="table-top">
                    <div class="search-set">
                        <!-- <div class="search-path">
                            <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                :class="{ setclose: filter }">
                                <img src="@/assets/img/icons/filter.svg" alt="img" />
                                <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                            </a>
                        </div> -->
                        <div class="search-input">
                            <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                    alt="img" /></a>
                            <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                <label>
                                    <input type="search" class="form-control form-control-sm"
                                        placeholder="Search..." aria-controls="DataTables_Table_0"
                                        v-model="query" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="wordset">
                        <ul>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                        src="@/assets/img/icons/pdf.svg" alt="img" /></a>
                            </li>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                        src="@/assets/img/icons/excel.svg" alt="img" /></a>
                            </li>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                        src="@/assets/img/icons/printer.svg" alt="img" /></a>
                            </li>
                        </ul>
                    </div> -->
                </div>
                <!-- /Filter -->
                <!-- <div class="card mb-0" id="filter_inputs" :style="{
                    display: filter ? 'block' : 'none',
                }">
                    <div class="card-body pb-0">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="row">
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Product" placeholder="Choose Product" />
                                        </div>
                                    </div>
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Choose" placeholder="Choose Category" />
                                        </div>
                                    </div>
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Category" placeholder="Choose Sub Category" />
                                        </div>
                                    </div>
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Brand" placeholder="Brand" />
                                        </div>
                                    </div>
                                    <div class="col-lg col-sm-6 col-12">
                                        <div class="form-group">
                                            <vue-select :options="Price" placeholder="Price" />
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-sm-6 col-12">
                                        <div class="form-group">
                                            <a class="btn btn-filters ms-auto"><img
                                                    src="@/assets/img/icons/search-whites.svg" alt="img" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- /Filter -->
                <div class="table-responsive">
                    <a-table :columns="columns" :data-source="filteredData" @change="onChange">
                        <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'no'">
                                <div class="productimgname">
                                    {{ record.no }}
                                </div>
                            </template>
                            <!-- <template v-if="column.key === 'nama'">
                                <div class="productimgname">
                                    <a href="javascript:void(0);" class="product-img">
                                        <img :src="require('@/assets/img/product/' + record.img)"
                                            alt="product" />
                                    </a>
                                    <a href="javascript:void(0);">{{ record.nama }}</a>
                                </div>
                            </template> -->
                            <!-- <template v-if="column.key === 'ongkos_angkut'">
                                <div class="productimgname">
  
                                    <a href="javascript:void(0);">{{ rupiah(record.ongkos_angkut) }}</a>
                                </div>
                            </template> -->
                            <template v-if="column.key === 'status_project'">
                                <div class="productimgname">
  
                                    <a href="javascript:void(0);">{{ record.status_project }}</a>
                                </div>
                            </template>
                            <template v-if="column.key === 'dokumen_url'">
                                <div class="productimgname">
                                    <a :href="record.dokumen_url" target="_blank" class="product-img me-2">
                                        <vue-feather type="file" data-bs-toggle="tooltip"
                                            title="fe fe-file"></vue-feather>
                                    </a>
                                </div>
                            </template>
  
  
                            <!-- <template v-else-if="column.key === 'action'" class="bg-danger">
                                
  
  
                                <router-link class="me-3" to="editproject" @click="edit(record);">
                                    <img src="@/assets/img/icons/edit.svg" alt="img" />
                                </router-link>
                                <a class="confirm-text" href="javascript:void(0);"
                                    @click="terminate(record.id); showModal('terminate');">
                                    <img src="@/assets/img/icons/delete.svg" alt="img" />
                                </a>
  
  
  
                            </template> -->
                        </template>
                    </a-table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- delete modal -->
    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">Hapus Data</h5>
                  <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <form @submit.prevent="delete2">
                  <div class="modal-body">
                      Apakah anda yakin ingin menghapus data ini?
                  </div>
                  <div class="modal-footer d-flex justify-content-end">
                      <button type="button" class="btn  btn-secondary" @click="hideModal2('terminate')">batal</button>
                      <button type="submit" class="btn  btn-danger">Hapus</button>
                  </div>
              </form>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom, rupiah, ImportExcel } from "@/assets/js/function";
import XLSX from "xlsx/dist/xlsx.full.min.js";
  


  export default {
      data() {
          return {
              filter: false,
              title: "Daftar Akun",
              title1: "Atur Daftar Akun",
              items: [
                  {
                      text: "Import",
                      addname: "/importdata",
                  },
                  {
                      text: "Export",
                      addname: "/export",
                  },
              ],
  
              //data
              data: [],
              columns: [
                  {
                      title: "No",
                      dataIndex: "no",
                      key: "no",
                      sorter: {
                          compare: (a, b) => {
                              a = a.no.toLowerCase();
                              b = b.no.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  {
                      title: "Tipe Akun",
                      dataIndex: "tipe_akun",
                      key: "tipe_akun",
                      sorter: {
                          compare: (a, b) => {
                              a = a.tipe_akun.toLowerCase();
                              b = b.tipe_akun.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  {
                      title: "Kode Perkiraan",
                      dataIndex: "kode_perkiraan",
                      key: "kode_perkiraan",
                      sorter: {
                          compare: (a, b) => {
                              a = a.kode_perkiraan.toLowerCase();
                              b = b.kode_perkiraan.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  
                  {
                      title: "Nama",
                      dataIndex: "nama",
                      key: "nama",
                      sorter: {
                          compare: (a, b) => {
                              a = a.nama.toLowerCase();
                              b = b.nama.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  {
                      title: "Akun Induk",
                      dataIndex: "akun_induk",
                      key: "akun_induk",
                      sorter: {
                          compare: (a, b) => {
                              a = a.akun_induk.toLowerCase();
                              b = b.akun_induk.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  {
                      title: "Mata Uang",
                      dataIndex: "mata_uang",
                      key: "mata_uang",
                      sorter: {
                          compare: (a, b) => {
                              a = a.mata_uang.toLowerCase();
                              b = b.mata_uang.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  {
                      title: "Saldo Awal",
                      dataIndex: "saldo_awal",
                      key: "saldo_awal",
                      sorter: {
                          compare: (a, b) => {
                              a = a.saldo_awal.toLowerCase();
                              b = b.saldo_awal.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  {
                      title: "Per Tanggal",
                      dataIndex: "per_tanggal",
                      key: "per_tanggal",
                      sorter: {
                          compare: (a, b) => {
                              a = a.per_tanggal.toLowerCase();
                              b = b.per_tanggal.toLowerCase();
                              return a > b ? -1 : b > a ? 1 : 0;
                          },
                      },
                  },
                  
  
                  // {
                  //     title: "Dokumen ",
                  //     dataIndex: "dokumen_url",
                  //     key: "dokumen_url",
                  //     sorter: {
                  //         compare: (a, b) => {
                  //             a = a.dokumen_url.toLowerCase();
                  //             b = b.dokumen_url.toLowerCase();
                  //             return a > b ? -1 : b > a ? 1 : 0;
                  //         },
                  //     },
                  // },
  
  
                //   {
                //       title: "Aksi",
                //       key: "action",
                //       sorter: false,
                //   },
              ],
  
              tanggal_terminate: "",
              alasan_terminate: "",
              id_terminate: "",
              nama_terminate: "",
  
              //filter
              query: "",
  
  
          };
  
  
      },
      created() {
          const token = Cookies.get("token_refresh");
          this.config = {
              headers: { Authorization: `Bearer ` + token },
              timeout: 30000,
          }
          this.index();
      },
  
      computed: {
          filteredData() {
              // Filter data based on selected jenis_kehadiran
              if (this.query == '') {
                  return this.data; // If no filter selected, return all data
              }
              const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison
  
              return this.data.filter(item =>
                  item.tipe_akun.toLowerCase().includes(lowerCaseQuery) ||
                  item.kode_perkiraan.toLowerCase().includes(lowerCaseQuery) ||
                  item.nama.toLowerCase().includes(lowerCaseQuery) ||
                  item.akun_induk.toLowerCase().includes(lowerCaseQuery) ||
                  item.mata_uang.toLowerCase().includes(lowerCaseQuery) ||
                  item.saldo_awal.toLowerCase().includes(lowerCaseQuery) 
                  
              );
          }
      },
      methods: {
          rupiah,
          showModal(modal_name) {
              this.$refs[modal_name].classList.add('show');
              this.$refs[modal_name].style.display = 'block';
          },
  
          hideModal2(modal_name) {
              this.$refs[modal_name].classList.remove('show');
              this.$refs[modal_name].style.display = 'none';
  
          },
        addNumberToObjects(objArray) {
            for (let i = 0; i < objArray.length; i++) {
                const newObj = { No: i + 1, ...objArray[i] };
                objArray[i] = newObj;
            }
            return objArray;
        },

        exportExcel() {

            ShowLoading();

            let api = "";
            let formData = new FormData();


            api = base_url + 'account';

            axios
                .post(api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        response.data.data = this.addNumberToObjects(response.data.data);
                         // Define a mapping for the column names to be renamed
                        const columnMappings = {
                            "jenis_account": "jenis akun",
                            "name": "nama akun",
                            "account_no": "no akun",
                            "group": "kelompok"
                        };

                        // Rename the columns while keeping the original order
                        for (let i = 0; i < response.data.data.length; i++) {
                            const row = response.data.data[i];
                            delete row["id"];
                            delete row["id_profiles"];
                            delete row["gambar"];
                            delete row["status"];
                            delete row["created_at"];
                            delete row["updated_at"];
                            
                            // Create a new object to hold the reordered columns
                            const reorderedRow = {};
                            for (const key in row) {
                                // Use the mapped name if it exists, otherwise keep the original key
                                const newKey = columnMappings[key] || key;
                                reorderedRow[newKey] = row[key];
                            }
                            // Replace the original row with the reordered row
                            response.data.data[i] = reorderedRow;
                        }

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();
                        // Create a new worksheet
                        const ws = XLSX.utils.json_to_sheet(response.data.data);
                        // Add the worksheet to the workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'Daftar Akun');
                        // Convert the workbook to an XLSX file and download it
                        XLSX.writeFile(wb, 'Daftar Akun' + ".xlsx");
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        importExcel() {
            ShowLoading();
            let formData = new FormData();

            // Create a file input element
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = '.xlsx, .xls';

            // Add an event listener to handle the file selection
            fileInput.addEventListener('change', (event) => {
                const file = event.target.files[0];
                if (!file) {
                    AlertPopup('error', '', 'Please select an Excel file to import.', 1500, false);
                    return;
                }

                // Read the Excel file
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    // Convert the first sheet to JSON
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    // Define column mappings for headers
                    const columnMappings = {
                        "tipe_akun": "Tipe Akun",
                        "kode_perkiraan": "Kode Perkiraan",
                        "nama": "Nama",
                        "akun_induk": "Akun Induk",
                        "mata_uang": "Mata Uang",
                        "saldo_awal": "Saldo Awal",
                        "per_tanggal": "Per Tanggal",
                    };

                    // Extract headers from the first row
                    const headers = jsonData[0].map(header => {
                        const matchedKey = Object.keys(columnMappings).find(key => columnMappings[key] === header);
                        return matchedKey || header; // Use mapped key or original header
                    });

                    // Format the JSON data and filter out empty rows
                    const formattedData = jsonData.slice(1).map((row) => {
                        const rowData = {};
                        headers.forEach((header, index) => {
                            if (header === 'kode_perkiraan') {
                                const kodePerkiraan = row[index] ? String(row[index]) : ''; // Convert to string or set to empty
                                rowData[header] = kodePerkiraan;
                            } else {
                                rowData[header] = row[index] || ''; // Other fields can just be the value or empty
                            }
                        });
                        return rowData;
                    }).filter((item) => {
                        // Include only rows where at least one of the fields is not empty, null, or undefined
                        return (
                            item.kode_perkiraan && 
                            typeof item.kode_perkiraan === 'string' && 
                            item.kode_perkiraan.trim() !== '' &&
                            // Ensure that at least one other field is filled
                            Object.values(item).some(value => value !== undefined && value !== null && value.toString().trim() !== '')
                        );
                       
                    });

                    console.log('Converted JSON Data:', formattedData);

                    formData.append("data", JSON.stringify(formattedData));

                    // Send the formatted JSON data to the API
                    const api = base_url + 'account_import';

                    axios
                        .post(api, formData, this.config)
                        .then((response) => {
                            console.log(response);
                            if (response.data.status === true) {
                                this.index(); // Update index after successful import
                                AlertBottom(response.data.message);
                                CloseLoading();
                            } else {
                                AlertPopup("error", "", response.data.message, 1500, false);
                            }
                        })
                        .catch((error) => {
                            AlertPopup('error', '', 'Failed to import data.', 1500, false);
                            console.error('Import error:', error);
                        });
                };

                // Read the file as an array buffer
                reader.readAsArrayBuffer(file);
            });

            // Trigger the file input click to open the file selector
            fileInput.click();
            CloseLoading()
        },

        
  
  
          // showAlert() {
          //     // Use sweetalert2
          //     Swal.fire({
          //         title: "Are you sure?",
          //         text: "You won't be able to revert this!",
          //         showCancelButton: true,
          //         confirmButtonColor: "#043249",
          //         cancelButtonColor: "#d33",
          //         confirmButtonText: "Yes, delete it!",
          //     }).then((result) => {
          //         if (result.isConfirmed) {
          //             Swal.fire("Deleted!", "Your file has been deleted.");
          //         }
          //     });
          // },
  
          index() {
              ShowLoading();
  
              if (process.env.NODE_ENV === "development") {
                  this.api = base_url + 'account';
              } else {
                  this.api = base_url + 'account';
              }
  
              axios
                  .post(this.api, '', this.config)
                  .then((response) => {
                      console.log(response)
                      if (response.data.status == true) {
                          this.data = [];
  
                          response.data.data.forEach((item, index) => {
                              let item_prepare = { ...item, no: (index + 1), };
                              this.data.push(item_prepare);
                          });
  
                        
  
                          CloseLoading();
                      }
  
  
                      if (response.data.status == false) {
                          AlertPopup("error", "", response.data.message, 1500, false);
                      }
                  })
                  .catch((error) => {
                      ErrorConnectionTimeOut(error);
                  });
  
          },
          edit(record) {
              localStorage.setItem('currentData', JSON.stringify(record));
          },
          delete2() {
              ShowLoading();
              let formData = new FormData();
              formData.append("id", this.id_terminate);
  
  
  
              // for (let [key, value] of formData.entries()) {
              //     console.log(`${key}: ${value}`);
              // }
  
              if (process.env.NODE_ENV === "development") {
                  this.api = base_url + 'projectsdelete';
              } else {
                  this.api = base_url + 'projectsdelete';
              }
  
              axios
                  .post(this.api, formData, this.config)
                  .then((response) => {
                      if (response.data.status == true) {
                          const index = this.data.findIndex((record) => record.id === this.id_terminate);
                          this.data.splice(index, 1);
                          CloseLoading();
                          AlertBottom(response.data.message);
                          this.hideModal2('terminate');
                      }
                      if (response.data.status == false) {
                          AlertPopup("error", "", response.data.message, 1500, false);
                      }
                  })
                  .catch((error) => {
  
                      ErrorConnectionTimeOut(error);
                  });
  
  
          },
          terminate(id) {
              this.id_terminate = id;
              this.tanggal_terminate = new Date();
          }
      },
      name: "productlist",
  };
  </script>