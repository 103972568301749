<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Nama Perusahaan<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.company" required />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="">NPWP</label>
                    <input type="text" minlength="15" maxlength="16" v-model="currentData.npwp">
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No Telepon</label>
                    <input type="text" v-model="currentData.telepone" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Industry</label>
                    <input type="text" v-model="currentData.industry" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="">Nama PIC Perusahaan</label>
                    <input type="text" v-model="currentData.nama_pic" required/>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Jabatan</label>
                    <input type="text" v-model="currentData.jabatan_pic" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No Whatsapp</label>
                    <input type="text" v-model="currentData.whatsapp" />
                  </div>
                </div>
                
                <div class="col-lg-6 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" v-model="currentData.email" />
                  </div>
                </div>
                
                
                
                <!-- <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Interest</label>
                    <input type="text" v-model="currentData.interest" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Discount Rate (%)</label>
                    <input type="text" v-model="currentData.discount_rate" />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Pricing Level<span class="text-danger">*</span></label>
                    <vue-select :options="pricingList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.rate" placeholder="None" required />
                  </div>
                </div> -->
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Alamat Penagihan</label>
                    <input type="text" class="mb-1" v-model="currentData.billing_address1" />
                    <input type="text" class="mb-1" v-model="currentData.billing_address2" />
                    <input type="text" class="mb-1" v-model="currentData.billing_address3" />
                  </div>



                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Alamat Pengiriman - [<a href="javascript:void(0);" @click="copyFromBillingAddres()">Salin
                        dari Alamat Penagihan</a>]</label>

                    <input type="text" class="mb-1" v-model="currentData.shipping_address1" />
                    <input type="text" class="mb-1" v-model="currentData.shipping_address2" />
                    <input type="text" class="mb-1" v-model="currentData.shipping_address3" />
                  </div>

                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="customer" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editcustomer",
  data() {
    return {
      filter: true,
      title: "Perbaharui Pelanggan",
      title1: "perbahru data pelanggan",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentData: {
        email: "",
        telepone: "",
        whatsapp: "",
        company: "",
        industry: "",
        billing_address1: "",
        billing_address2: "",
        billing_address3: "",
        shipping_address1: "",
        shipping_address2: "",
        shipping_address3: "",
        npwp: "",
        nama_pic: "",
        jabatan_pic: "",


      },
      // pricingList: [
      //   {
      //     "id": "0",
      //     "text": "Regular"
      //   },
      //   {
      //     "id": "2",
      //     "text": "Silver"
      //   },
      //   {
      //     "id": "3",
      //     "text": "Gold"
      //   },
      //   {
      //     "id": "4",
      //     "text": "Bronze"
      //   },
      //   {
      //     "id": "5",
      //     "text": "Platinum"
      //   },
      // ],
      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");

    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    //get data
    this.currentData = JSON.parse(localStorage.getItem('currentData'));
    // this.currentData.dob = new Date(this.currentData.dob);

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {

    copyFromBillingAddres() {

      this.currentData.shipping_address1 = this.currentData.billing_address1;
      this.currentData.shipping_address2 = this.currentData.billing_address2;
      this.currentData.shipping_address3 = this.currentData.billing_address3;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // for (let [key, value] of formData.entries()) {
      //           console.log(`${key}: ${value}`);
      //       }
      // CloseLoading();
      // return true;

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'customersupdate';
      } else {
        this.api = base_url + 'customersupdate';
      }

      // formData.delete("dob");

      // if (this.currentData.dob != 'Invalid Date' && this.currentData.dob != new Date()) {
      //   var formattedDate = this.currentData.dob.toISOString().split('T')[0];
      //   formData.append("dob", formattedDate);
      // } else {
      //   formData.append("dob", null);
      // }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("customer");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
