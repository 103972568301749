<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar-menu></sidebar-menu>
      <div class="page-wrapper">
        <div class="content">
          <pageheader :title="title" :title1="title1" />
  
          <!-- /add -->
          <div class="card">
            <div class="card-body">
              <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
              <form @submit.prevent="insert">
  
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Dibayar dari </label>
                      <!-- <model-select :options="" placeholder="Pilih Projek" v-model="currentData.id_project">
                      </model-select> -->
                      <input type="text" v-model="currentData.nama_customer" placeholder=""  />

                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Bank </label>
                      <input type="text" v-model="currentData.nama_customer" placeholder=""  />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label>No. Voucher </label>
                      <input type="text" v-model="currentData.route" placeholder=""  />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label>No. Cek</label>
                      <input type="text" v-model="currentData.jenis_muatan" placeholder=""  />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Tanggal Penerimaan</label>
                      <div class="input-groupicon" style="z-index: 100;">
                        <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.so_date"
                           />
                        <a class="addonset">
                          <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Memo </label>
                      <input type="text" v-model="currentData.memo" placeholder=""  />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-12">
                    <div class="form-group">
                      <label>Total </label>
                      <input type="text" v-model="currentData.total" placeholder=""  />
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-6 col-12">
                    <div class="form-group">
                      <div class="d-flex align-items-center">

                      <label class="me-2">Terbilang : </label>
                        <button type="button" class="btn ms-auto" @click="addRow">
                          <i class='bx bx-message-square-add' style="font-size: 24px;"></i>
                        </button>

                      </div>
                      <!-- <input type="text" v-model="currentData.total" placeholder="" disabled /> -->
                      <table class="table table-bordered">
                            <thead class="thead-primary">
                                <tr>
                                    <th>No. Akun</th>
                                    <th>Nama Akun</th>
                                    <th>Total</th>
                                    <th>Memo</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in records" :key="index">
                                    <td><input type="text" v-model="item.no_akun" class="form-control" /></td>
                                    <td><input type="text" v-model="item.nama_akun" class="form-control" /></td>
                                    <td><input type="text" v-model="item.total_terbilang"  @input="formatCurrencyTable('total_terbilang', item.total_terbilang, index)"  class="form-control" placeholder="Rp 0,-" /></td>
                                    <td><input type="text" v-model="item.memo" class="form-control" /></td>
                                    <td>
                                      
                                      <a class="confirm-text" href="javascript:void(0);"
                                          @click="deleteRow(index);">
                                          <img src="@/assets/img/icons/delete.svg" alt="img" />
                                      </a>
                                      <!-- <a class="confirm-text" href="javascript:void(0);"
                                          @click="terminate(record.id); showModal('terminate');">
                                          <img src="@/assets/img/icons/delete.svg" alt="img" />
                                      </a> -->
                                    </td>
                                </tr>
                                
                            </tbody>
                      </table>

                    </div>
                  </div>
                  
  
                  
  
                  
  
  
  
                  <div class="col-lg-12">
                    <button type="submit" class="btn btn-submit me-2">Simpan & Baru</button>
                    <button type="submit" class="btn btn-outline-submit me-2">Simpan & Tutup</button>
                    <router-link to="kaspengeluaran" class="btn btn-cancel">Batal</router-link>
                  </div>
                </div>
  
              </form>
            </div>
          </div>
          <!-- /add -->
        </div>
      </div>
    </div>
</template>

<style scoped>
.btn-outline-primary {
    min-width: 120px !important;
    /* background: #043249; */
    color: #043249;;
    font-size: 14px;
    font-weight: 700;
    padding: 14px 10px !important;
    transition: all 0.2s ease;
    border-color: #043249; /* Match the border color to the background color */
}

.btn-outline-primary:hover {
    background: #03202e; /* Darker shade for hover effect */
    border-color: #03202e; /* Match border color for hover */
}
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiahInput, rupiahoutput, moneyFormat } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Perbaharui Form Pengeluaran ",
      title1: "perbaharui form pengeluaran ",
      config: "",
      api: "",

      //data
      currentData: {
        // permintaan: "",
        // uang_jalan: "",
        // uang_tinggal: "",
        // // uang_langsir: "",
        // bonus_premi: "",
        // // bonus: "",
        // id_asset: "",
        // id_driver: "",
        // jenis_aset: "",
        // nama_customer: "",
      },
      


    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    // this.prepareData();
    // this.currentData.tanggal_muat = new Date();
    // this.currentData.tanggal_bongkar = new Date();

  },
 
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    formatCurrency(label, number) {
      this.currentData[label] = rupiahInput(number);
    },
    // prepareData() {
    //   // needData1
    //   const needData1 = JSON.parse(localStorage.getItem('needData1'));
    //   needData1.forEach((item, index) => {
    //     const element = {
    //       value: item.id, text: item.nama,
    //       lokasi_muat: item.lokasi_muat,
    //       lokasi_bongkar: item.lokasi_bongkar,
    //       jenis_muatan: item.jenis_muatan,
    //       route: item.route,
    //       // uang_jalan: item.uang_jalan,
    //       // uang_langsir: item.uang_langsir,
    //       // bonus_premi: item.bonus_premi,
    //       // uang_tinggal: item.uang_tinggal,
    //       // uang_tambahan: item.uang_tambahan,
    //       nama_customer: item.nama_customer,
    //       sto_no: item.sto_no,
    //       do_no: item.do_no,
    //       spk_no: item.spk_no,
    //       po_no: item.po_no,
    //       toleransi_susut: item.toleransi_susut,
    //       so_qty: item.so_qty,
    //       so_date: item.so_date,
    //     }

    //     this.needData1.push(element);
    //   });

    //   // needData2
    //   const needData2 = JSON.parse(localStorage.getItem('needData2'));
    //   needData2.forEach((item, index) => {
    //     const element = { value: item.id, text: item.nama, id_asset: item.id_asset }
    //     this.needData2.push(element);
    //   });

    //   // needData3
    //   const needData3 = JSON.parse(localStorage.getItem('needData3'));
    //   needData3.forEach((item, index) => {
    //     const element = { value: item.id, text: item.nama, jenis: item.jenis, nama_asset: item.nama_asset }
    //     this.needData3.push(element);
    //   });

    //   const approveData = JSON.parse(localStorage.getItem('approveData'));
    //   if (approveData != null) {

    //     this.currentData.id_asset = approveData.id_assets;
    //     this.currentData.id_driver = approveData.id_drivers;


    //     const index = this.needData3.findIndex((record) => record.value === approveData.id_assets);
    //     if (index > -1) {
    //       this.currentData.jenis_aset = this.needData3[index].jenis;
    //     }
    //   }

    // },
    
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

    //   formData.append("jenis", 'KHUSUS');

    //   //tanggal muat

    //   formData.delete("tanggal_muat");
    //   if (this.currentData.tanggal_muat != 'Invalid Date' && this.currentData.tanggal_muat != new Date()) {
    //     var formattedDate = this.currentData.tanggal_muat.toISOString().split('T')[0];
    //     formData.append("tanggal_muat", formattedDate);
    //   } else {
    //     formData.append("tanggal_muat", null);
    //   }

    //   //tanggal bongkar
    //   formData.delete("tanggal_bongkar");
    //   if (this.currentData.tanggal_bongkar != 'Invalid Date' && this.currentData.tanggal_bongkar != new Date()) {
    //     var formattedDate = this.currentData.tanggal_bongkar.toISOString().split('T')[0];
    //     formData.append("tanggal_bongkar", formattedDate);
    //   } else {
    //     formData.append("tanggal_bongkar", null);
    //   }

    //   formData.delete("is_supervisor");
    //   if (this.currentData.is_supervisor == true) {
    //     formData.append('is_supervisor', 'AKTIF');
    //   } else {
    //     formData.append('is_supervisor', 'TIDAK');
    //   }


    //   formData.delete("uang_jalan");
    //   formData.append('uang_jalan', rupiahoutput(this.currentData.uang_jalan));
    //   formData.delete("uang_tinggal");
    //   formData.append('uang_tinggal', rupiahoutput(this.currentData.uang_tinggal));
    //   // formData.delete("tambahan");
    //   // formData.append('tambahan', rupiahoutput(this.currentData.tambahan));
    //   // formData.delete("uang_tinggal");
    //   // formData.append('uang_tinggal', rupiahoutput(this.currentData.uang_tinggal));
    //   // formData.delete("uang_langsir");
    //   // formData.append('uang_langsir', rupiahoutput(this.currentData.uang_langsir));
    //   formData.delete("bonus");
    //   formData.append('bonus', rupiahoutput(this.currentData.bonus));

    //   // formData.delete("langsir");
    //   // formData.append('langsir', rupiahoutput(this.currentData.langsir));
    //   formData.delete("bonus_premi");
    //   formData.append('bonus_premi', rupiahoutput(this.currentData.bonus_premi));

    //   if (this.$refs.file.value != "") {
    //     formData.append("file", this.$refs.file.files[0]);
    //   }


      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // CloseLoading();
      // return false;



    //   if (process.env.NODE_ENV === "development") {
    //     this.api = base_url + 'operationalinsert';
    //   } else {
    //     this.api = base_url + 'operationalinsert';
    //   }

      // formData.append("jenis", "KHUSUS");
      // console.log(this.api)

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            localStorage.removeItem("approveData");

            goPage("");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>