<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nama Perusahaan</label>
                    <model-select :options="needData1" placeholder="Pilih Perusahaan" v-model="currentData.id_customer"
                      style="z-index: 1001;">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Rute </label>
                    <model-select :options="needData2" placeholder="Pilih Rute" v-model="currentData.id_route"
                      style="z-index: 1001;">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Jenis Muatan </label>
                    <model-select :options="needData3" placeholder="Pilih Jenis Muatan"
                      v-model="currentData.id_jenis_muatan">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal SO </label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.so_date" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor PO</label>
                    <input type="text" v-model="currentData.po_no" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor DO</label>
                    <input type="text" v-model="currentData.do_no" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor STO</label>
                    <input type="text" v-model="currentData.sto_no" placeholder="" required />
                  </div>
                </div>
                
                
                
                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Nomor SO </label>
                    <input type="text" v-model="currentData.so_no" placeholder="" required />
                  </div>
                </div> -->
                <div class="col-lg-3 col-sm-6 col-12">
                  <!-- <div class="form-group">
                    <label>Tanggal Penagihan SO </label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false"
                        v-model="currentData.so_billing_date" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div> -->

                  <div class="form-group">
                    <label>Nomor SPK </label>
                    <input type="text" v-model="currentData.spk_no" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Ketentuan SO </label>
                    <input type="text" v-model="currentData.so_term" placeholder="" required />
                  </div>
                </div>
                
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Kuantitas SO </label>
                    <div class="position-relative w-100">
                      <input type="number" v-model="currentData.so_qty" @input="formatedNumber(currentData.so_qty)" placeholder="" required />
                      <div class="position-absolute top-0 end-0 me-4 pe-2 h-100 d-flex align-items-center">kg</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Ongkos Angkut </label>
                    <input type="text" class="mb-1" v-model="currentData.ongkos_angkut" placeholder="Price"
                      @input="formatCurrency(currentData.ongkos_angkut)" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Toleransi Susut  </label>
                    <div class="position-relative w-100">
                      <input type="number" v-model="currentData.toleransi_susut" placeholder="" />
                      <div class="position-absolute top-0 end-0 me-4 pe-2 h-100 d-flex align-items-center">%</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12 mb-2 mt-2">
                  <div class="form-group">
                    <label>Berkas Dokumen </label>
                    
                    <a :href="currentData.dokumen_url" class="mt-2" target="_blank"><i class='bx bx-link me-2' ></i>Lihat Dokumen</a>
                    <input type="file" class="form-control mt-2" ref="file" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12"></div>
                <div class="col-lg-3 col-sm-6 col-12 mb-3">
                  <div class="form-group">
                    <label>Tipe</label>
                    <vue-select :options="statusTripList" v-model="currentData.status_project" placeholder="None"
                      required />
                  </div>
                </div>
                <div class="col-12 mb-5" v-if="currentData.status_project == 'MASALAH'">
                  <div class="form-group">
                    <label>penjelasan projek</label>
                    <textarea class="form-control" v-model="currentData.alasan">
                    </textarea>
                  </div>
                </div>

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="project" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-group input[type="number"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="number"]:focus {
  border-color: #043249;
  background: #fff;
}
</style>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiahInput, rupiahoutput, moneyInputFormat, moneyOutputFormat } from "@/assets/js/function";
import { ModelSelect } from 'vue-search-select';
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect,
  },
  name: "editbank",
  data() {
    return {
      filter: true,
      title: "Edit Jenis Muatan",
      title1: "edit Jenis Muatan data",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentData: {
      },
      needData1: [],
      needData2: [],
      needData3: [],

      statusTripList: ["AKTIF", "MASALAH"],

      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }



    //get data
    this.currentData = JSON.parse(localStorage.getItem('currentData'));

    this.prepareData();

    this.currentData.so_date = new Date(this.currentData.so_date);
    this.currentData.so_billing_date = new Date(this.currentData.so_billing_date);

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    formatCurrency(number) {

      this.currentData['ongkos_angkut'] = rupiahInput(number);

    },
    formatedNumber(number) {

      this.currentData['so_qty'] = moneyInputFormat(number);

    },
    prepareData() {

      // needData1
      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      needData1.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        this.needData1.push(element);
      });



      // needData1
      const needData2 = JSON.parse(localStorage.getItem('needData2'));
      needData2.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        this.needData2.push(element);
      });

      // needData1
      const needData3 = JSON.parse(localStorage.getItem('needData3'));
      needData3.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        this.needData3.push(element);
      });

      this.currentData.so_qty = moneyInputFormat(this.currentData.so_qty);
      this.currentData.ongkos_angkut = rupiahInput(this.currentData.ongkos_angkut);

    },

    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("ongkos_angkut");
      formData.append("ongkos_angkut", rupiahoutput(this.currentData.ongkos_angkut));
      formData.delete("so_qty");
      formData.append("so_qty", moneyOutputFormat(this.currentData.so_qty));

      if (this.$refs.file.value != "") {
        formData.append("file", this.$refs.file.files[0]);
      }
      

      // formData.delete("so_date");

      // if (this.currentData.so_date != 'Invalid Date' && this.currentData.so_date != new Date()) {
      //   var formattedDate = this.currentData.so_date.toISOString().split('T')[0];
      //   formData.append("so_date", formattedDate);
      // } else {
      //   formData.append("so_date", null);


      // }
      formData.delete("so_date");
      const date = new Date(this.currentData.so_date);
      const formattedDate = date.getFullYear() + '-' +
          String(date.getMonth() + 1).padStart(2, '0') + '-' +
          String(date.getDate()).padStart(2, '0');

      formData.append("so_date", formattedDate);

      // formData.delete("so_billing_date");

      // if (this.currentData.so_billing_date != 'Invalid Date' && this.currentData.so_billing_date != new Date()) {
      //   var formattedDate = this.currentData.so_billing_date.toISOString().split('T')[0];
      //   formData.append("so_billing_date", formattedDate);
      // } else {
      //   formData.append("so_billing_date", null);

      // }

      for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
      // CloseLoading();
      // return true;

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'projectsupdate';
      } else {
        this.api = base_url + 'projectsupdate';
      }


      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("project");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
