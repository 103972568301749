<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageaddheader :title="title" :title1="title1" :items="items" />
                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top">
                            <div class="search-set">
                                <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="@/assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Search..." aria-controls="DataTables_Table_0"
                                                v-model="query" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <a-table :columns="columns" :data-source="filteredData" @change="onChange">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'jenis_muatan'">
                                        <div v-if="record.jenis_muatan != ''">
                                            <div class="mb-3 fw-bold">{{ record.jenis_muatan }}</div>
                                            <!-- <div class="mb-3" v-if="record.lokasi_bongkar != ''">
                                                <div>Tipe : {{ record.jenis }}</div>
                                                <div>Supir : {{ record.driver }}</div>
                                                <div>Aset : {{ record.asset }}</div>
                                            </div> -->
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'route'">
                                        <div class="mb-3 fw-bold">{{ record.route }}</div>
                                        <!-- <div class="mb-3" v-if="record.lokasi_muat != ''">
                                            <div class="fw-bold">Muat</div>


                                            <div>Lokasi Muat : {{ record.lokasi_muat }}</div>
                                            <div>Tanggal Muat: {{ record.tanggal_muat }}</div>
                                            <div>Tanggal Aktual: {{ record.tanggal_actual_muat }}</div>
                                            <div>Jam Muat: {{ record.jam_muat }}</div>
                                        </div>
                                        <div class="mb-3" v-if="record.lokasi_bongkar != ''">
                                            <div class="fw-bold">Bongkar</div>
                                            <div>Lokasi Bongkar : {{ record.lokasi_bongkar }}</div>
                                            <div>Tanggal Bongkar: {{ record.tanggal_bongkar }}</div>
                                            <div>Tanggal Aktual: {{ record.tanggal_actual_bongkar }}</div>
                                            <div>Jam Bongkar: {{ record.jam_bongkar }}</div>
                                        </div> -->
                                    </template>

                                    <template v-if="column.key === 'foto_muatan_1'">
                                        <div class="mb-3 fw-bold">Muat</div>
                                        <div>Timbang Muat 1: {{ record.qty }}/{{ record.satuan_qty }}</div>
                                        <div>Timbang Muat 2: {{ record.qty_2 }}/{{ record.satuan_qty_2 }}</div>
                                        <div v-if="record.foto_muatan_1 != ''">
                                            <div>
                                                <a :href="record.foto_muatan_1" target="_blank"
                                                    class="product-img me-2 ">
                                                    Foto Muatan 1
                                                    <!-- <vue-feather type="file" data-bs-toggle="tooltip"
                                                        title="fe fe-file"></vue-feather> -->
                                                </a>
                                            </div>

                                        </div>
                                        <div v-if="record.foto_muatan_2 != ''">
                                            <!-- <div class="mb-3 ">Foto Muatan 2</div> -->
                                            <div>
                                                <a :href="record.foto_muatan_2" target="_blank"
                                                    class="product-img me-2">
                                                    Foto Muatan 2
                                                    <!-- <vue-feather type="file" data-bs-toggle="tooltip"
                                                        title="fe fe-file"></vue-feather> -->
                                                </a>
                                            </div>
                                        </div>
                                        <div v-if="record.foto_spb != ''" class="mb-3">
                                            <!-- <div class="mb-3 ">Foto SPB</div> -->
                                            <div>
                                                <a :href="record.foto_spb" target="_blank" class="product-img me-2">
                                                    Foto SPB
                                                    <!-- <vue-feather type="file" data-bs-toggle="tooltip"
                                                        title="fe fe-file"></vue-feather> -->
                                                </a>
                                            </div>
                                        </div>
                                        <div class="mb-3 fw-bold">Bongkar</div>
                                        <div>Timbang Bongkar 1: {{ record.qty_bongkar }}/{{ record.satuan_qty_bongkar }}
                                        </div>
                                        <div>Timbang Bongkar 2: {{ record.qty_bongkar_2 }}/{{
                                            record.satuan_qty_bongkar_2 }}</div>
                                        <div v-if="record.foto_bongkar_1 != ''">
                                            <!-- <div class="mb-3 ">Foto Bongkar 1</div> -->
                                            <div>
                                                <a :href="record.foto_bongkar_1" target="_blank"
                                                    class="product-img me-2">
                                                    Foto Bongkar 1
                                                    <!-- <vue-feather type="file" data-bs-toggle="tooltip"
                                                        title="fe fe-file"></vue-feather> -->
                                                </a>
                                            </div>
                                        </div>
                                        <div v-if="record.foto_bongkar_2 != ''">
                                            <!-- <div class="mb-3 ">Foto Bongkar 2</div> -->
                                            <div>
                                                <a :href="record.foto_bongkar_2" target="_blank"
                                                    class="product-img me-2">
                                                    <!-- <vue-feather type="file" data-bs-toggle="tooltip"
                                                        title="fe fe-file"></vue-feather> -->
                                                    Foto Bongkar 2
                                                </a>
                                            </div>
                                        </div>
                                        <div v-if="record.foto_spb != ''">
                                            <!-- <div class="mb-3 ">Foto SPB Bongkar</div> -->
                                            <div>
                                                <a :href="record.foto_spb_bongkar" target="_blank"
                                                    class="product-img me-2">
                                                    Foto SPB Bongkar
                                                    <!-- <vue-feather type="file" data-bs-toggle="tooltip"
                                                        title="fe fe-file"></vue-feather> -->
                                                </a>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'doc_action'">
                                        <div class="productimgname">
                                            <a href="javascript:void(0);" @click="img_lampiran(record); showModal('detail_doc');" class="product-img me-2">
                                                <!-- <vue-feather type="file" data-bs-toggle="tooltip"
                                                    title="fe fe-file"></vue-feather> -->
                                                    <i class='bx bx-link' style="font-size: 24px;" ></i>
                                            </a>
                                        </div>
                                    </template>
                                    <template v-else-if="column.key === 'action'">
                                        <!-- <a class="me-3" @click="show_izitoast">
                                            <img src="@/assets/img/icons/eye.svg" alt="img" />
                                        </a> -->
                                        <router-link class="me-3" to="edittripreguler" @click="edit(record);">
                                            <img src="@/assets/img/icons/edit.svg" alt="img" />
                                        </router-link>
                                        <a class="confirm-text" href="javascript:void(0);"
                                            @click="terminate(record.id); showModal('terminate');">
                                            <img src="@/assets/img/icons/delete.svg" alt="img" />
                                        </a>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>

    <!-- modal untuk delete -->
    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Hapus Data</h5>
                    <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="delete2">
                    <div class="modal-body">
                        Apakah anda yakin ingin menghapus data ini?
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <button type="button" class="btn  btn-secondary" @click="hideModal2('terminate')">batal</button>
                        <button type="submit" class="btn  btn-danger">Hapus</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- modal untuk lihat gambar dari tripnya -->
    <div class="modal fade modal-berkas" id="detail_doc" ref="detail_doc" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Detail Berkas | ( {{  currentData.so_no }} )</h5>
                    <button type="button" class="close" @click="hideModal2('detail_doc')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- <form @submit.prevent="delete2"> -->
                <form @submit.prevent="detail2">
                    <div class="modal-body px-5">
                        <div class="container-fluid">
                            <div class="row">
                                
                                <div class="col-md-6 align-items-center mb-4">
                                    <h1>Muat</h1>
                                    <div class="row mb-3">
                                        
                                        <div class="col-12 col-md-6">
                                            <p class="mb-0">Timbang Muat 1: {{ currentData.qty }}/{{ currentData.satuan_qty }}</p>
                                            <p class="mb-0">Timbang Muat 2: {{ currentData.qty_2 }}/{{ currentData.satuan_qty_2 }}</p>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <p class="mb-0">Tanggal Muat : {{ currentData.tanggal_muat }}</p>
                                            <p class="mb-0">Tanggal Bongkar : {{ currentData.tanggal_bongkar }}</p>
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="row">

                                        <div class="col-12 col-md-4  d-flex justify-content-start align-items-start flex-column">
                                            <!-- <img src="https://via.placeholder.com/100" alt="alt-img"> -->
                                            <a :href="currentData.foto_spb" target="_blank">
                                                
                                                <div class="" v-if="currentData.foto_spb != '' ">
                                                    <img :src="currentData.foto_spb" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                                <div class="" v-else>
                                                    <img src="https://acs-api.arthaskyline.com/images/default-images.png" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                            </a>
                                            <div class="mb-0">
                                                <p>SPB Muat</p>
                                            </div>
                                        </div>
    
                                        <div class="col-12 col-md-4 d-flex justify-content-start align-items-start flex-column">
                                            <!-- <img src="https://via.placeholder.com/100" alt="alt-img"> -->
                                            <a :href="currentData.foto_muatan_1" target="_blank">
                                                
                                                <div class="" v-if="currentData.foto_muatan_1 != '' ">
                                                    <img :src="currentData.foto_muatan_1" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                                <div class="" v-else>
                                                    <img src="https://acs-api.arthaskyline.com/images/default-images.png" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                            </a>
                                            <div class="mb-0">
                                                <p>Timbang Muat 1</p>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4  d-flex justify-content-start align-items-start flex-column">
                                            
                                            <a :href="currentData.foto_muatan_2" target="_blank">
                                                
                                                <div class="" v-if="currentData.foto_muatan_2 != '' ">
                                                    <img :src="currentData.foto_muatan_2" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                                <div class="" v-else>
                                                    <img src="https://acs-api.arthaskyline.com/images/default-images.png" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                            </a>
                                            <div class="mb-0">
                                                <p>Timbang Muat 2</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>


                                <div class="col-md-6 align-items-center">
                                    <h1>Bongkar</h1>
                                    <div class="row mb-3">
                                        
                                        <div class="col-12 col-md-6">
                                            <p class="mb-0">Timbang Muat 1: {{ currentData.qty }}/{{ currentData.satuan_qty }}</p>
                                            <p class="mb-0">Timbang Muat 2: {{ currentData.qty_2 }}/{{ currentData.satuan_qty_2 }}</p>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <p class="mb-0">Tanggal Muat : {{ currentData.tanggal_muat }}</p>
                                            <p class="mb-0">Tanggal Bongkar : {{ currentData.tanggal_bongkar }}</p>
                                        </div>
                                        
                                    </div>

                                    <div class="row d-flex flex-wrap">
                                    
                                        <div class="col-12 col-md-4 d-flex justify-content-start align-items-start flex-column">
                                            <a :href="currentData.foto_spb_bongkar" target="_blank">
                                                <div class="" v-if="currentData.foto_spb_bongkar != '' ">
                                                    <img :src="currentData.foto_spb_bongkar" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">
                                                    
                                                </div>
                                                <div class="" v-else>
                                                    <img src="https://acs-api.arthaskyline.com/images/default-images.png" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                                

                                            </a>
                                           
                                            <div class="mb-0">
                                                <p>SPB Bongkar</p>
                                            </div>
                                        </div>
                                        
                                        <div class="col-12 col-md-4 d-flex justify-content-start align-items-start flex-column">
                                            
                                            <a :href="currentData.foto_bongkar_1" target="_blank">
                                                
                                                <div class="" v-if="currentData.foto_bongkar_1 != '' ">
                                                    <img :src="currentData.foto_bongkar_1" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                                <div class="" v-else>
                                                    <img src="https://acs-api.arthaskyline.com/images/default-images.png" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                            </a>
                                            <div class="mb-0">
                                                <p>Timbangan Bongkar 1</p>
                                                
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 d-flex justify-content-start align-items-start flex-column">
                                            
                                            <a :href="currentData.foto_bongkar_2" target="_blank">
                                                
                                                <div class="" v-if="currentData.foto_bongkar_2 != '' ">
                                                    <img :src="currentData.foto_bongkar_2" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                                <div class="" v-else>
                                                    <img src="https://acs-api.arthaskyline.com/images/default-images.png" alt="alt-img" class="rounded-2" width="100" height="100" rel="noopener noreferrer">

                                                </div>
                                            </a>
                                            <div class="mb-0">
                                                <p>Timbang Bongkar 2</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                

                            

                                
                            </div>
                        </div>
                    </div>
                    <!-- <div class="modal-footer d-flex justify-content-end mt-5">
                        <button type="button" class="btn  btn-secondary" @click="hideModal2('detail_doc')">Close</button>
                        
                    </div> -->
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal-berkas {
    z-index: 1101;
    
}

.modal-berkas .modal-body {
    max-height: calc(100vh - 30px); /* Adjust as needed for your layout */
    overflow-y: auto; /* Enables vertical scrolling */
}
</style>


<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";

export default {
    data() {
        return {
            filter: false,
            title: "Daftar Trip Reguler",
            title1: "Atur Trip Reguler",
            items: [
                {
                    text: "Tambah Trip Reguler",
                    addname: "/addtripreguler",
                },
            ],

            //data
            data: [],
            columns: [
                {
                    title: "No",
                    dataIndex: "no",
                    key: "no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.no.toLowerCase();
                            b = b.no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Status Trip",
                    dataIndex: "status_trip",
                    key: "status_trip",
                    sorter: {
                        compare: (a, b) => {
                            a = a.status_trip.toLowerCase();
                            b = b.status_trip.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Nomor SO",
                    dataIndex: "so_no",
                    key: "so_no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.so_no.toLowerCase();
                            b = b.so_no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Muatan",
                    dataIndex: "jenis_muatan",
                    key: "jenis_muatan",
                    sorter: {
                        compare: (a, b) => {
                            a = a.jenis_muatan.toLowerCase();
                            b = b.jenis_muatan.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Supir",
                    dataIndex: "driver",
                    key: "driver",
                    sorter: {
                        compare: (a, b) => {
                            a = a.driver.toLowerCase();
                            b = b.driver.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Aset",
                    dataIndex: "asset",
                    key: "asset",
                    sorter: {
                        compare: (a, b) => {
                            a = a.asset.toLowerCase();
                            b = b.asset.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Rute",
                    dataIndex: "route",
                    key: "route",
                    sorter: {
                        compare: (a, b) => {
                            a = a.route.toLowerCase();
                            b = b.route.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                // {
                //     title: "Tanggal Muat",
                //     dataIndex: "tanggal_muat",
                //     key: "tanggal_muat",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.tanggal_muat.toLowerCase();
                //             b = b.tanggal_muat.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Tanggal Bongkar",
                //     dataIndex: "tanggal_bongkar",
                //     key: "tanggal_bongkar",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.tanggal_bongkar.toLowerCase();
                //             b = b.tanggal_bongkar.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Tanggal Muat Aktual",
                //     dataIndex: "tanggal_actual_muat",
                //     key: "tanggal_actual_muat",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.tanggal_actual_muat.toLowerCase();
                //             b = b.tanggal_actual_muat.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Tanggal Bongkar Aktual",
                //     dataIndex: "tanggal_actual_bongkar",
                //     key: "tanggal_actual_bongkar",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.tanggal_actual_bongkar.toLowerCase();
                //             b = b.tanggal_actual_bongkar.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Jam Muat Aktual",
                //     dataIndex: "jam_muat",
                //     key: "jam_muat",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.jam_muat.toLowerCase();
                //             b = b.jam_muat.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Jam Bongkar Aktual",
                //     dataIndex: "jam_bongkar",
                //     key: "jam_bongkar",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.jam_bongkar.toLowerCase();
                //             b = b.jam_bongkar.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },


                // {
                //     title: "Tipe",
                //     dataIndex: "jenis",
                //     key: "jenis",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.jenis.toLowerCase();
                //             b = b.jenis.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Rute",
                //     dataIndex: "route",
                //     key: "route",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.route.toLowerCase();
                //             b = b.route.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Supir",
                //     dataIndex: "driver",
                //     key: "driver",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.driver.toLowerCase();
                //             b = b.driver.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Aset",
                //     dataIndex: "asset",
                //     key: "asset",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.asset.toLowerCase();
                //             b = b.asset.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },

                // {
                //     title: "Dokumen",
                //     dataIndex: "foto_muatan_1",
                //     key: "foto_muatan_1",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.foto_muatan_1.toLowerCase();
                //             b = b.foto_muatan_1.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Foto Muatan",
                //     dataIndex: "foto_muatan",
                //     key: "foto_muatan",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.foto_muatan.toLowerCase();
                //             b = b.foto_muatan.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Foto Surat Jalan",
                //     dataIndex: "foto_surat_jalan",
                //     key: "foto_surat_jalan",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.foto_surat_jalan.toLowerCase();
                //             b = b.foto_surat_jalan.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                // {
                //     title: "Foto Dokumen Lain",
                //     dataIndex: "foto_dokumen_lain",
                //     key: "foto_dokumen_lain",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.foto_dokumen_lain.toLowerCase();
                //             b = b.foto_dokumen_lain.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                {
                    title: "Berkas",
                    key: "doc_action",
                    sorter: false,
                },
                {
                    title: "Action",
                    key: "action",
                    sorter: false,
                },
            ],

            currentData: {},

            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
            nama_terminate: "",

            //filter
            query: "",
        };
    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }
        this.index();

        localStorage.removeItem("approveData");
    },

    computed: {
        filteredData() {
            // Filter data based on selected jenis_kehadiran
            if (this.query == '') {
                return this.data; // If no filter selected, return all data
            }
            const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison

            return this.data.filter(item =>
                item.tanggal_muat.toLowerCase().includes(lowerCaseQuery) ||
                item.status_trip.toLowerCase().includes(lowerCaseQuery) ||
                item.so_no.toLowerCase().includes(lowerCaseQuery) ||
                item.lokasi_muat.toLowerCase().includes(lowerCaseQuery) ||
                item.lokasi_bongkar.toLowerCase().includes(lowerCaseQuery) ||
                item.jenis_muatan.toLowerCase().includes(lowerCaseQuery) ||
                item.jenis.toLowerCase().includes(lowerCaseQuery) ||
                item.route.toLowerCase().includes(lowerCaseQuery) ||
                item.driver.toLowerCase().includes(lowerCaseQuery) ||
                item.asset.toLowerCase().includes(lowerCaseQuery)

            );
        }
    },
    methods: {

        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },



        // showAlert() {
        //     // Use sweetalert2
        //     Swal.fire({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         showCancelButton: true,
        //         confirmButtonColor: "#043249",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, delete it!",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire("Deleted!", "Your file has been deleted.");
        //         }
        //     });
        // },

        index() {
            ShowLoading();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'operational';
            } else {
                this.api = base_url + 'operational';
            }

            let formData = new FormData();
            formData.append('jenis', 'REGULER');

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response)
                    if (response.data.project != undefined) {
                        localStorage.setItem('needData1', JSON.stringify(response.data.project));
                        console.log(response.data.project);
                    }
                    if (response.data.driver != undefined) {
                        localStorage.setItem('needData2', JSON.stringify(response.data.driver));


                    }
                    if (response.data.asset != undefined) {
                        localStorage.setItem('needData3', JSON.stringify(response.data.asset));

                        console.log(response.data.asset);
                    }

                    if (response.data.status == true) {
                        this.data = [];

                        response.data.data.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data.push(item_prepare);
                        });



                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        edit(record) {
            localStorage.setItem('currentData', JSON.stringify(record));
        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);



            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'operationaldelete';
            } else {
                this.api = base_url + 'operationaldelete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        terminate(id) {
            this.id_terminate = id;
            this.tanggal_terminate = new Date();
        },

        detail2(){
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.currentData);
            
            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            
        },
        img_lampiran(record){
            // this.id_det_img = id;
            this.currentData = record;
        }
    },
    name: "productlist",
};
</script>
<style>
.ant-tooltip {
    display: none !important;
}

td {
    vertical-align: top !important;
}
</style>